import React, { Component } from 'react';
import axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Category from './category';
import List from './list/list';
import Two from './list/two';
import Large from './list/large';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../general/loader';
import Subcategory from './subcategory';
import backArrow from '../../assets/backArrow.png'
import CategoryItemsDisplay from './CategoryItemsDisplay';
import 'react-photo-view/dist/react-photo-view.css';
import { PhotoProvider, PhotoView } from 'react-photo-view';

class Menu extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        categories: [],
        selectCategory: [],
        items: [],
        loadCat: false,
        load: false,
        listview: "two",
        categoriesIsFixed: false,
        isManualScrolling: false
    }

    async componentDidMount() {

        // Get Items
        let id = window.location.pathname.replace("/menu/", "");

        // Get categories
        const {data} = await axios.get("https://manage.caviarmenu.com/api/categories/pref");
        this.setState({ categories: data }, ()=>{
            this.setState({loadCat: true});
            this.getCategoryName(id);
        });

        {
            const {data} = await axios.get("https://manage.caviarmenu.com/api/items/pref");
            this.setState({ items: data }, ()=>{
                this.setState({load: true});
                setTimeout( () => {
                    this.handleCategory(id);
                },800)
            });
        }

        this.layoutDirection();

        window.addEventListener('scroll', (e) => {             
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;            
            if(scrollPosition > 80) {
                this.setState({ categoriesIsFixed: true });
            } else {
                this.setState({ categoriesIsFixed: false });
            }
        });

        this.setState({ categoriesIsFixed: false });

    }

    layoutDirection() {
        if(this.props.Language != "en")
            document.getElementById("itemContainer").style.direction = "rtl";
        else
            document.getElementById("itemContainer").style.direction = "ltr";
    }

    getCategoryName(id) {
        if(id != -1)
            this.state.categories.map(cat => {
                if(cat.id == id) this.setState({ selectCategory: cat });
            });
        else
            this.setState({ selectCategory: this.state.categories[0] });
    }

    handleChange = async e => {
        await this.props.handleLangaugeSubmit(e);
        this.layoutDirection();
    }

    handleCategory = async (catId) => {
        
        this.setState({ isManualScrolling: true, load: false }, async () => {
            const element = document.getElementById('cat_' + catId);
    
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
    
            await this.getCategoryName(catId);
    
            this.setState({ load: true });
    
            setTimeout(() => {
                this.setState({ isManualScrolling: false });
            }, 1000); 
        });
    };
    

    handleListview = style => {
        this.setState({listview: style});
    }

    handleShowLangSelect = e => {
        this.setState({showLangSelect: e});
    }

    headCover = {'backgroundImage':`url('${this.settings.introCover}')`, 'backgroundSize':'cover', 'backgroundPosition':'center'}



    render() {


        const categoryLinks = document.querySelectorAll('.categories-item');
        const categorySections = document.querySelectorAll('.category-section');

        function updateActiveCategory(activeId)  {
                    
            let activeLink = null;
        
            categoryLinks.forEach(link => {
                if (link.dataset.target === activeId) {
                    link.classList.add('active');
                    activeLink = link;
                } else {
                    link.classList.remove('active');
                }
            });
        
            if (activeLink) {
                activeLink.scrollIntoView({ behavior: 'smooth', inline: 'start' });
            }
        };
        
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !this.state.isManualScrolling) {
                    updateActiveCategory(entry.target.id);
                }
            });
        }, { threshold: 0.2 });

        categorySections.forEach(section => {            
            observer.observe(section);
        });

        const isLtr = (this.props.Language == "en");


        return (
            <div className='menu-items'>
                <div className="head" style={this.headCover} >

                    <div className="language">
                        <div 
                                className='language-active' 
                                // style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}}
                                onClick={ () => this.handleShowLangSelect(true) }>
                                    <i className="fa-solid fa-globe"></i>
                                    {
                                        this.props.Language == "en"? 'Eng'
                                        : this.props.Language == "krd"? 'Ku'
                                        : 'Ar'
                                    }
                                    <i className="fa-solid fa-chevron-down"></i>
                        </div>
                        
                    </div>

                    <Link to={"/home"}>
                        <img src={this.settings.menuLogo} className='logo' alt="" />
                    </Link>
                </div>

                <div className={`categories ${this.state.categoriesIsFixed? 'categories-bg': '' }`} style={{'direction': `${this.props.Language != "en"? 'rtl': 'ltr'}`}}>

                    {
                        this.state.loadCat ?
                        <> 
                            { this.state.categories.map(cat => <Category key={cat.id}  Data = {cat} Language = {this.props.Language} handleCategory = { this.handleCategory} Active = {this.state.selectCategory.id == cat.id} ></Category>) }
                        </>
                        : <Loader></Loader>
                    }
                    
                </div>

                <div className='items' id='itemContainer'>

                    {
                        this.state.loadCat ?
                            <PhotoProvider maskOpacity={0.7} photoClassName={'photoProviderImg'} overlayRender={ ({ images, index }) => {

                                if(!images[index]){
                                    return null;
                                }
                                const data = images[index].overlay;                                                                
                                                            
                                return (
                                    <div className={`item-photo-view`} style={ {'direction': isLtr? "ltr" : "rtl"} }>
                                        <div >
                                            <h5 style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}}>
                                                {
                                                    this.props.Language == "en" ? data.name_eng
                                                    : this.props.Language == "krd" ? data.name_krd
                                                    : data.name_ar
                                                }          
                                            </h5>
                                        </div>
                                        

                                        <div className='price'>
                                            <span> { parseInt(data.price).toLocaleString('en-US')}</span> <span className='currency'>{(this.props.Language == "en" ||  this.props.Language == "tr")? 'IQD': 'د.ع.'}</span> 
                                        </div>

                                        <p>
                                            {
                                                this.props.Language == "en" ? data.desc_eng
                                                : this.props.Language == "krd" ? data.desc_krd
                                                : data.desc_ar
                                            }
                                        </p>

                                    </div>
                                );
                        }} >
                            
                            <CategoryItemsDisplay categories={this.state.categories} items={this.state.items} language = {this.props.Language} />

                        </PhotoProvider>
                        : <Loader></Loader>

                    }

                    

                    
                </div>



                <div 
                        className={`language-wrapper ${this.state.showLangSelect? '': 'd-none'}`} 
                        onClick={ () => this.handleShowLangSelect(false)} >
                    <div className={`language-select`}>
                        <div className='language-select-head'><span>Language</span> <i className="fa-solid fa-angle-up"></i></div>
                        <ul>
                            <li className={`${this.props.Language === "krd"? 'language-select-active': ''}`} onClick={() => this.handleChange('krd')}>کوردی</li>
                            <li className={`${this.props.Language === "ar"? 'language-select-active': ''}`} onClick={() => this.handleChange('ar')}>العربیة</li>
                            <li className={`${this.props.Language === "en"? 'language-select-active': ''}`} style={{'textAlign': 'left'}} onClick={() => this.handleChange('en')}>English</li>
                        </ul>
                    </div>
                </div>
                
                
            </div>
        );
    }
}

export default Menu;