import React, { Component } from 'react';
import Imgloading from './itemloading';
import { PhotoView } from 'react-photo-view';
import gsap from 'gsap';

class Two extends Component {

    data = this.props.Data;
    language = this.props.Language;

    componentDidMount() {
        
        // gsap.fromTo(".item", {
        //     opacity: 0,
        //     y: function(index, target, targets) { 
        //       return index * 50;
        //     },
        //   },
        //   {
        //     opacity: 1,
        //     y: 0,
        //     duration: 1
        //   });

    }

    priceText(text){
        if(text == 'small')
            
            return (
                <span> 
                    {
                        this.props.Language == "en"? 'small'
                        : this.props.Language == "krd"? 'بچیک'
                        : 'صغیر'
                    } 
                </span>
            )
        else if(text == 'medium')
            return (
                <span>
                    {
                        this.props.Language == "en"? 'medium'
                        : this.props.Language == "krd" ? 'ناڤنجی'
                        : 'متوسط'
                    }
                </span>
            )
        else
            return <span>
                {
                    this.props.Language == "en" ? 'large'
                    : this.props.Language == "krd" ? 'مەزن'
                    : 'کبير'
                }
            </span>
    }

    render() {
        return ( 
            <div className="item">
                
                <PhotoView key={this.data.id} src={this.data.image} overlay={this.data}>
                    <div className="image">
                        <Imgloading Img={this.data.image} />
                    </div>
                </PhotoView>

                <div className="info" style={ this.props.Language == "en"? {'fontFamily':'Urbanist'}: {}}>
                    <h5>
                        {
                            this.props.Language == "en" ? this.data.name_eng
                            : this.props.Language == "krd" ? this.data.name_krd
                            : this.data.name_ar
                        } 
                    </h5>
                    <div className='price'> 
                    {
                        (this.data.price_small && this.data.price_max)? 
                            <>
                                
                                <span> 
                                    { this.priceText('small') } 
                                    {this.data.price_small} 
                                </span>
                                <span> 
                                    { this.priceText('medium') } 
                                    {this.data.price} 
                                </span> 
                                <span> 
                                    { this.priceText('big') }
                                    {this.data.price_max} 
                                </span> 
                            </>
                        :
                        (this.data.price_small)?
                        <>
                            
                            <span> 
                                { this.priceText('small') }
                                {this.data.price_small} 
                            </span>

                            <span> 
                                { this.priceText('medium') } 
                                {this.data.price} 
                            </span> 

                        </>
                        :
                        (this.data.price_max)?
                        <>
                            <span> 
                                { this.priceText('medium') }  
                                {this.data.price} 
                            </span> 
                            <span> 
                                { this.priceText('big') }
                                {this.data.price_max} 
                            </span> 
                        </>
                        :
                        <span> {this.data.price} 
                            <span className='currency'>
                                {(this.props.Language == "en")? 'IQD': 'د.ع.'} 
                            </span> 
                        </span> 

                    }   
                    </div>
                </div>
            </div>
        );
    }
}

export default Two;